import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import theme from '../../theme'
import Block from '../Block'
import Box from '../primitives/Box'

export type GraphQLArticle = {
  date: string
  title: string
  slug: string
  showCoverImage: boolean
  excerpt: string
  meta: {
    image: any // eslint-disable-line @typescript-eslint/no-explicit-any
    imageAlt?: string
  }
}

export type FeaturedArticlesProps = {
  articles: 'latest' | string[]
  heading: string
  articlesData: GraphQLArticle[]
  newStyles?: boolean
}

export const FeaturedArticles = ({
  articles,
  heading,
  articlesData,
  newStyles,
}: FeaturedArticlesProps) => {
  const filteredArticles =
    articles === 'latest'
      ? articlesData
      : (articles
          .map(slug => articlesData.find(article => article.slug === slug))
          .filter(Boolean) as GraphQLArticle[])

  if (filteredArticles.length === 0) {
    return null
  }

  return (
    <div className="main-body-old-styles">
      <Block centered={true} size="large" type="padding-large">
        {newStyles ? (
          <Box.h6 font="h6" color="dataPurple" textTransform="uppercase">
            {heading}
          </Box.h6>
        ) : (
          <h6 className="label">{heading}</h6>
        )}
        <ArticleContainer>
          {filteredArticles.slice(0, 3).map(article => {
            return (
              <BlogArticle
                {...article}
                image={article.meta.image}
                link={article.slug}
                key={article.slug}
              />
            )
          })}
        </ArticleContainer>
      </Block>
    </div>
  )
}

const ArticleContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: center;
  gap: 24px;

  @media (max-width: 1315px) {
    gap: 12px;
  }

  @media (max-width: 1080px) {
    grid-template-columns: auto;
  }
`

const BlogArticle = ({
  title,
  date,
  image,
  imageAlt,
  showCoverImage,
  excerpt,
  link,
}: {
  title: string
  date: string
  image: any // eslint-disable-line @typescript-eslint/no-explicit-any
  imageAlt?: string
  showCoverImage: boolean
  excerpt: string
  link: string
}) => {
  const gatsbyImage = getImage(image)
  return (
    <ArticleCard href={link}>
      <ArticleDate>{date}</ArticleDate>
      <ArticleTitle>{title}</ArticleTitle>
      {showCoverImage !== false ? (
        <GatsbyImage
          style={{ marginTop: 16, borderRadius: 6 }}
          imgStyle={{ width: '100%' }}
          alt={imageAlt ?? ''}
          image={gatsbyImage!}
        />
      ) : (
        <ArticleExcerpt>{excerpt}</ArticleExcerpt>
      )}
      <ReadArticle>
        <h5>Read now</h5>
        <span> →</span>
      </ReadArticle>
    </ArticleCard>
  )
}

const ArticleCard = styled.a`
  display: flex;
  flex-direction: column;
  min-width: 320px;
  max-width: 820px;
  height: fit-content;
  margin: 0 auto;
  padding: 16px 28px;
  border-radius: 12px;

  &:hover {
    background: ${theme.colors.blue50};
  }
`

const ArticleDate = styled.span`
  color: ${theme.colors.black500};
  font-size: 14px;
  font-weight: 600;
  align-self: start;
  margin-bottom: 6px;
`

const ArticleTitle = styled.h3`
  &&& {
    text-align: start;
    line-height: 33px;
    margin-top: 0;
    margin-bottom: 0;
  }
`

const ReadArticle = styled.span`
  margin-top: 0.75rem;
  align-self: start;

  h5 {
    display: inline;
    text-decoration: underline;
    text-decoration-color: #f3b3e0;
    text-underline-offset: 6px;
    font-weight: bold;
    font-size: 16px;
  }

  span {
    transition: all 0.15s ease-out;
    position: relative;
    top: 1px;
    left: -2px;
    font-weight: 500;
  }

  ${ArticleCard}:hover & {
    transform: scale(1.02);

    h5 {
      text-decoration-color: ${theme.colors.dataPink};
    }
    span {
      left: 0px;
    }
  }
`

const ArticleExcerpt = styled.p`
  text-align: start;
  font-size: 16px;
  line-height: 24px;
`
